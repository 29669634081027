<template #page-content>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Office</h2>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button
            tag="button"
            class="is-command"
            icon-left="plus"
            @click.native="openModalCreate()"
          >
            New Office
          </b-button>
        </div>
      </div>
    </template>
    <template #page-content>
      <div class="columns is-multiline">
        <div class="column is-4" v-for="(item, index) in data" :key="index">
          <r-card
            @click.native="openSidebar(item)"
            class="pointer-click office-card"
          >
            <div class="columns is-multiline">
              <div class="is-12 office-picture-container">
                <img :src="getImageURL(item)" />
                <b-icon
                  icon="pencil-circle"
                  class="office-button-container office-pointer-click"
                  @click.native="openModalCreate(index)"
                ></b-icon>
              </div>
              <div class="column is-12 office-text-bold">
                {{ item ? item.locationName : '-' }}
              </div>
              <div class="column is-12 office-text-semi-bold">
                {{ item ? item.country + ' - ' + item.city : '-' }}
              </div>
              <div class="column is-12 office-text-regular">
                {{
                  item
                    ? textSubString(item.address, 80, '...') +
                      ' - ' +
                      item.postalCode
                    : '-'
                }}
              </div>
              <div class="column is-6 pl-0 pb-1 pt-1">
                <div class="column is-12 person-text-regular">Max Capacity</div>
                <div class="column is-12 person-capacity">
                  {{ item ? item.maxCapacity : '-' }}
                  <span>people</span>
                </div>
              </div>
              <div class="column is-6 pl-0 pb-1 pt-1">
                <div class="column is-12 person-text-regular">
                  Allowed Capacity
                </div>
                <div class="column is-12 person-capacity">
                  {{
                    item.activeSetting ? item.activeSetting.maxCapacity : '-'
                  }}
                  <span>people</span>
                </div>
              </div>
            </div>
          </r-card>
        </div>
      </div>

      <OfficeIndexModal
        :show="isModalOpen"
        :loadingSubmit="isSubmitting"
        :formData="currentRow"
        :lat="lat"
        :lng="lng"
        @hide="closeModalCreate"
        @submit="submit"
        @deleteDropFile="deleteDropFile"
        @onFileChange="onFileChange"
      />

      <AllowedCapModal
        :show="isModalWFOOpen"
        :loadingSubmit="isSubmittingWFO"
        :formData="allowedCapRow"
        @hide="closeModalWFOCreate"
        @submit="submitAllowedCap"
      />
    </template>
    <template #page-sidebar>
      <b-sidebar
        v-if="showRow"
        position="static"
        right
        v-model="isSideBarOpen"
        fullheight
      >
        <r-card outlined>
          <div class="level">
            <div class="level-left">
              <h3 class="mb-0">{{ showRow.locationName }}</h3>
            </div>
            <div class="level-right">
              <b-icon
                icon="close"
                class="office-pointer-click"
                size="is-small"
                @click.native="closeSidebar()"
              ></b-icon>
            </div>
          </div>
          <div class="office-picture-container">
            <img :src="getImageURL(showRow)" />
          </div>
          <div class="office-text-bold-sidebar pb-4">
            Company Details
            <span>
              <b-icon
                class="is-pulled-right pointer-click pt-2"
                size="is-small"
                icon="delete"
                @click.native="deleteAlert(showRow.id)"
              ></b-icon>
            </span>
          </div>
          <div class="columns is-multiline">
            <div class="column is-4 office-text-regular-sidebar">Address</div>
            <div class="column is-8 office-text-semibold-sidebar is-inline">
              {{ showRow.address ? showRow.address : '-' }}
            </div>

            <div class="column is-12"></div>

            <div class="column is-4 office-text-regular-sidebar">Province</div>
            <div class="column is-8 office-text-semibold-sidebar is-inline">
              {{ showRow.province ? showRow.province : '-' }}
            </div>
            <div class="column is-4 office-text-regular-sidebar">City</div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.city ? showRow.city : '-' }}
            </div>
            <div class="column is-4 office-text-regular-sidebar">Country</div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.country ? showRow.country : '-' }}
            </div>
            <div class="column is-4 office-text-regular-sidebar">
              Postal Code
            </div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.postalCode ? showRow.postalCode : '-' }}
            </div>
            <div
              class="column is-12"
              v-if="showRow && showRow.markers.length > 0"
            >
              <GoogleMap
                class="location-wrapper"
                :value="showRow.markers"
              ></GoogleMap>
            </div>
            <div class="column is-4 office-text-regular-sidebar">Latitude</div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.markers[0].lat }}
            </div>

            <div class="column is-4 office-text-regular-sidebar">Longitude</div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.markers[0].lng }}
            </div>
            <div class="column is-4 office-text-regular-sidebar">Radius</div>
            <div
              class="column is-8 office-text-semibold-sidebar"
              v-if="showRow.wfoRadius"
            >
              {{ showRow.wfoRadius }} meter(s)
            </div>
            <div class="column is-8 office-text-semibold-sidebar" v-else>-</div>
            <div class="column is-4 office-text-regular-sidebar">
              WFO Required
            </div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.wfoRequired === 1 ? 'Yes' : 'No' }}
            </div>
            <div class="column is-4 office-text-regular-sidebar">Longitude</div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.markers[0].lng }}
            </div>

            <div class="column is-4 office-text-regular-sidebar">
              Max Capacity
            </div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.maxCapacity ? showRow.maxCapacity + ' People' : '-' }}
            </div>
            <div class="column is-12 mt-0">
              <hr class="black-divider" />
            </div>
          </div>
          <div class="office-text-bold-sidebar pt-1 pb-4">Allowed Capacity</div>
          <div class="columns is-multiline">
            <div class="column is-4 office-text-regular-sidebar">
              Allowed Capacity
            </div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{
                showRow.activeSetting
                  ? showRow.activeSetting.maxCapacity + ' People'
                  : '-'
              }}
            </div>
            <div class="column is-4 office-text-regular-sidebar">Date Plan</div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{
                showRow.activeSetting
                  ? formatDate(
                      new Date(showRow.activeSetting.startAt),
                      'DD MMMM'
                    ) +
                    ' - ' +
                    formatDate(
                      new Date(showRow.activeSetting.endAt),
                      'DD MMMM YYYY'
                    )
                  : '-'
              }}
            </div>
          </div>
        </r-card>
      </b-sidebar>
    </template>
  </r-page>
</template>
<script>
import ApiService from '@/services/common/api.service'
import { showToast } from '@/services/util'
import moment from 'moment'

// component
import OfficeIndexModal from './OfficeIndexModal.vue'
import AllowedCapModal from './AllowedCapModal.vue'
import GoogleMap from '../../../components/GoogleMap'

export default {
  name: 'OfficeIndex',
  components: {
    GoogleMap,
    OfficeIndexModal,
    AllowedCapModal,
  },
  computed: {
    lat: function () {
      if (this.currentRow.markers.length === 0) {
        return null
      }
      return this.currentRow.markers[0].lat
    },
    lng: function () {
      if (this.currentRow.markers.length === 0) {
        return null
      }
      return this.currentRow.markers[0].lng
    },
  },
  data() {
    return {
      data: [],
      form: [],
      showRow: null,
      currentRow: {
        id: null,
        address: null,
        locationName: null,
        country: null,
        city: null,
        province: null,
        postalCode: null,
        wfoRadius: null,
        wfoRequired: null,
        maxCapacity: null,
        locationImage: null,
        markers: [],
      },
      allowedCapRow: {},
      isEditing: false,
      isSubmitting: false,
      isSubmittingWFO: false,
      isModalOpen: false,
      isModalWFOOpen: false,
      fileImagetoShow: null,
      isSideBarOpen: false,
      isEditingAllowedCap: false,
      defaultLocImage: {},
      selectedLocation: {},
      url: null,
      img: null,
    }
  },
  async mounted() {
    this.getApiLocations()
    this.getApiWFOSettings()
  },
  methods: {
    onFileChange(e) {
      const file = e
      this.url = URL.createObjectURL(file)
      URL.revokeObjectURL(file)
    },

    /**
     * Date Formatter
     * @param {Date} date - date data
     * @param {String} format - format date that we want to use
     */
    formatDate(date, format) {
      return moment(date).format(format)
    },

    /**
     * Open sidebar
     * @param {Object} row - selected row object
     */
    openSidebar(row) {
      this.isSideBarOpen = true
      this.showRow = {
        id: row.id,
        address: row.address,
        locationName: row.locationName,
        country: row.country,
        city: row.city,
        province: row.province,
        postalCode: row.postalCode,
        maxCapacity: row.maxCapacity,
        wfoRadius: row.wfoRadius,
        wfoRequired: row.wfoRequired,
        activeSetting: row.activeSetting,
        locationImage: row.locationImage,
        markers: [
          {
            lat: +row.lat,
            lng: +row.long,
          },
        ],
      }
      this.fileImagetoShow = row.locationImage
    },

    /**
     * Close Sidebar
     */
    closeSidebar() {
      this.isSideBarOpen = false
      this.showRow = null
    },

    /**
     * Get location from API
     * @return {Object} data - Location data
     */
    getApiLocations() {
      ApiService.get('/api/space-roketin/location').then((response) => {
        this.data = response.data
      })
    },

    /**
     * Open Delete sweet alert
     * @param {integer} id - data id that we want to delete
     */
    deleteAlert(id) {
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        titleText: 'Are you sure you want to delete this office?',
      }).then(async (res) => {
        if (res && res.isConfirmed) {
          await ApiService.delete('/api/space-roketin/location/' + id).then(
            () => {
              this.$swal({
                icon: 'success',
                titleText: 'Thank You',
                confirmButtonText: 'Done',
                customClass: {
                  confirmButton: 'button is-success',
                },
              })
              this.closeSidebar()
              this.getApiLocations()
            }
          )
        }
      })
    },

    /**
     * Get WFO settings data
     * @return {object} form - WFO Settings data
     */
    getApiWFOSettings() {
      ApiService.get('/api/space-roketin/settings/wfo-settings').then(
        (response) => {
          this.form = response.data
        }
      )
    },

    /**
     * Get selected image url from table data
     * @param {Object} item - Image object used to get the URL
     */
    getImageURL(item) {
      return item.locationImage
        ? process.env.VUE_APP_API_URL + item.locationImage
        : '/images/default-profile-picture-male.png'
    },

    /**
     * Open Create Modal
     * @param {integer} index - ID to update Office Data (Optional)
     */
    async openModalCreate(index) {
      this.selectedLocation = this.data[index]
      if (this.selectedLocation) {
        this.isEditing = true
        this.currentRow = {
          id: this.selectedLocation.id,
          address: this.selectedLocation.address,
          locationName: this.selectedLocation.locationName,
          country: this.selectedLocation.country,
          city: this.selectedLocation.city,
          province: this.selectedLocation.province,
          postalCode: this.selectedLocation.postalCode,
          wfoRadius: this.selectedLocation.wfoRadius,
          wfoRequired: this.selectedLocation.wfoRequired,
          maxCapacity: this.selectedLocation.maxCapacity,
          locationImage: this.selectedLocation.locationImage,
          markers: [
            {
              lat: +this.selectedLocation.lat,
              lng: +this.selectedLocation.long,
            },
          ],
        }
        this.fileImagetoShow = this.selectedLocation.locationImage
        this.defaultLocImage = this.selectedLocation.locationImage
      }
      this.isModalOpen = true
    },

    /**
     * Open allowed capacity modal
     * @param {object} row - Submitted location form
     * @param {integer} id - Id Office location
     */
    openModalAllowedCapCreate(row, id) {
      if (this.selectedLocation) {
        let activeSetting = this.selectedLocation.activeSetting
        if (activeSetting) {
          this.isEditingAllowedCap = true
          this.allowedCapRow = {
            id: activeSetting.id,
            locationId: id,
            maxCapacity: activeSetting.maxCapacity,
            startAt: new Date(activeSetting.startAt),
            endAt: new Date(activeSetting.endAt),
            isActive: activeSetting.isActive == 1,
          }
        } else {
          this.allowedCapRow = {
            id: null,
            locationId: id,
            maxCapacity: 0,
            startAt: null,
            endAt: null,
            isActive: false,
          }
        }
      } else {
        this.allowedCapRow = {
          id: null,
          locationId: id,
          maxCapacity: 0,
          startAt: null,
          endAt: null,
          isActive: false,
        }
      }
    },

    /**
     * Close create/ update modal
     */
    closeModalCreate() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    /**
     * Close create/ update
     * work from office modal
     */
    closeModalWFOCreate() {
      this.resetWFOForm()
      this.isModalWFOOpen = false
      this.isEditing = false
    },

    /**
     * delete image file
     */
    deleteDropFile() {
      this.currentRow.locationImage = null
      this.url = null
    },

    /**
     * Reset form when modal close
     */
    resetForm() {
      this.currentRow = {
        id: '',
        address: '',
        locationName: '',
        country: '',
        city: '',
        province: '',
        postalCode: '',
        wfoRadius: '',
        wfoRequired: 0,
        maxCapacity: null,
        locationImage: null,
        markers: [],
      }
      this.url = null
    },

    /**
     * Reset WFO form when modal close
     */
    resetWFOForm() {
      this.allowedCapRow = {
        id: '',
        locationId: '',
        maxCapacity: null,
        startAt: '',
        endAt: '',
        isActive: 0,
      }
      this.isEditingAllowedCap = false
    },

    /**
     * Submit office location data
     */
    async submit() {
      ApiService.setHeaderMultipartFormData()
      let form = new FormData()
      this.isSubmitting = true

      try {
        if (!this.isEditing) {
          form.append('location_name', this.currentRow.locationName)
          form.append('address', this.currentRow.address)
          form.append('country', this.currentRow.country)
          form.append('city', this.currentRow.city)
          form.append('province', this.currentRow.province)
          form.append('postal_code', this.currentRow.postalCode)
          form.append('wfo_radius', this.currentRow.wfoRadius)
          form.append('wfo_required', this.currentRow.wfoRequired)
          form.append('max_capacity', this.currentRow.maxCapacity)
          form.append('location_image', this.currentRow.locationImage)
          form.append('lat', this.currentRow.markers[0].lat)
          form.append('long', this.currentRow.markers[0].lng)

          let response = await ApiService.post(
            '/api/space-roketin/location',
            form,
            true
          )
          this.currentRow = {
            // id: '',
            address: '',
            locationName: '',
            country: '',
            city: '',
            province: '',
            postalCode: '',
            wfoRadius: '',
            wfoRequired: 0,
            maxCapacity: null,
            locationImage: null,
          }

          this.data = []
          this.getApiLocations()
          showToast('Add Success', 'is-success', 'is-top')
          this.isModalOpen = false
          this.isModalWFOOpen = true

          this.openModalAllowedCapCreate(form, response.data.data.id)
        } else {
          form.append('location_name', this.currentRow.locationName)
          form.append('address', this.currentRow.address)
          form.append('country', this.currentRow.country)
          form.append('city', this.currentRow.city)
          form.append('province', this.currentRow.province)
          form.append('postal_code', this.currentRow.postalCode)
          form.append('wfo_radius', this.currentRow.wfoRadius)
          form.append('wfo_required', this.currentRow.wfoRequired)
          form.append('max_capacity', this.currentRow.maxCapacity)
          form.append('lat', this.currentRow.markers[0].lat)
          form.append('long', this.currentRow.markers[0].lng)

          if (this.currentRow.locationImage !== this.defaultLocImage) {
            form.append('location_image', this.currentRow.locationImage)
          }

          form.append('_method', 'PUT')

          await ApiService.post(
            '/api/space-roketin/location/' + this.currentRow.id,
            form,
            true
          )

          this.data = []
          this.getApiLocations()
          this.isSubmitting = true
          this.closeSidebar()
          showToast('Data is successfully edited!', 'is-success', 'is-top')
          this.isModalOpen = false
          this.isModalWFOOpen = true
          this.openModalAllowedCapCreate(form, this.currentRow.id)
        }
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-top')
      }

      this.isSubmitting = false
      this.isEditing = false

      this.resetForm()
      this.isModalOpen = false
    },

    /**
     * Submit allowed capacity form (from WFO modal)
     */
    async submitAllowedCap() {
      this.isSubmittingWFO = true

      try {
        if (!this.isEditingAllowedCap) {
          let allowedCapForm = {
            location_id: this.allowedCapRow.locationId,
            max_capacity: this.allowedCapRow.maxCapacity,
            start_at: this.allowedCapRow.startAt
              ? moment(this.allowedCapRow.startAt).format('YYYY-MM-DD')
              : null,
            end_at: this.allowedCapRow.endAt
              ? moment(this.allowedCapRow.endAt).format('YYYY-MM-DD')
              : null,
            is_active: this.allowedCapRow.isActive,
          }

          await ApiService.post(
            '/api/space-roketin/settings/wfo-settings',
            allowedCapForm,
            true
          )
          this.allowedCapRow = {
            id: '',
            locationId: '',
            maxCapacity: null,
            startAt: '',
            endAt: '',
            isActive: 0,
          }

          this.getApiLocations()
          // this.getApiWFOSettings()
          showToast('Add Success', 'is-success', 'is-top')
        } else {
          let allowedCapForm = {
            location_id: this.allowedCapRow.locationId,
            max_capacity: this.allowedCapRow.maxCapacity,
            start_at: moment(this.allowedCapRow.startAt).format('YYYY-MM-DD'),
            end_at: moment(this.allowedCapRow.endAt).format('YYYY-MM-DD'),
            is_active: this.allowedCapRow.isActive,
          }

          await ApiService.put(
            '/api/space-roketin/settings/wfo-settings/' + this.allowedCapRow.id,
            allowedCapForm,
            true
          )

          this.getApiLocations()
          // this.getApiWFOSettings()
          showToast('Add Success', 'is-success', 'is-top')
        }
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-top')
      }

      this.isSubmittingWFO = false
      this.isEditing = false

      this.resetWFOForm()
      this.isModalWFOOpen = false
    },
  },
}
</script>
