<template>
  <div class="base-map">
    <b-button
      v-if="markerDeletable"
      icon-left="delete"
      @click="deleteMarkers"
      type="button"
      value="Delete Markers"
      class="mb-1"
    >
      Clear Markers
    </b-button>

    <b-input
      id="pac-input"
      type="text"
      placeholder="Search Place"
      v-if="enablePlaceMarker"
    />
    <div class="map map-content" ref="mymap"></div>
  </div>
</template>
<script>
import gmapsInit from '@/services/gmap'
import { getLocation, showToast } from '@/services/util'

export default {
  name: 'GoogleMap',
  props: {
    enablePlaceMarker: Boolean,
    limitPickMarker: Number,
    value: Array,
    markerDeletable: Boolean,
    draggableMarker: Boolean,
    radius: [Number, String],
  },

  computed: {
    coords: function () {
      let coords = []
      this.markersObj.forEach((m) =>
        coords.push({ lat: m.position.lat(), lng: m.position.lng() })
      )
      return coords
    },
  },

  data() {
    return {
      markersObj: [],
      map: null,
      drawCircle: null,
    }
  },

  methods: {
    async initMap(center) {
      const google = await gmapsInit()

      // Init map with center and zoom
      this.map = new google.maps.Map(this.$refs.mymap, {
        zoom: 10,
        center,
      })

      let bounds = new google.maps.LatLngBounds()

      // bounds.extend(center)
      // this.map.fitBounds(bounds)

      // //* for fitbound purpose

      for (let i = 0; i < this.value.length; i++) {
        this.addMarker(
          new google.maps.Marker({
            index: i,
            position: this.value[i],
            map: this.map,
            draggable: this.draggableMarker,
          })
        )

        //*extend bound
        bounds.extend(this.value[i])
      }

      if (!bounds.isEmpty()) {
        this.map.fitBounds(bounds)
      }

      // events
      if (this.enablePlaceMarker) {
        this.map.addListener('click', (event) => {
          if (this.markersObj.length < this.limitPickMarker) {
            this.addMarker(
              new google.maps.Marker({
                position: event.latLng,
                map: this.map,
                draggable: this.draggableMarker,
              })
            )
          }

          this.placeMarkerAndPanTo(event.latLng)
        })

        // Create the search box and link it to the UI element.
        if (this.enablePlaceMarker) {
          const input = document.getElementById('pac-input')
          const searchBox = new google.maps.places.SearchBox(input)

          this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input)
          // Bias the SearchBox results towards current map's viewport.
          this.map.addListener('bounds_changed', () => {
            searchBox.setBounds(this.map.getBounds())
          })

          // Listen for the event fired when the user selects a prediction and retrieve
          // more details for that place.
          searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces()

            if (places.length == 0) {
              return
            }
            // Clear out the old markers.
            this.markersObj.forEach((marker) => {
              marker.setMap(null)
            })
            this.markersObj = []
            // this.coords = []

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds()
            places.forEach((place) => {
              if (!place.geometry || !place.geometry.location) {
                console.log('Returned place contains no geometry')
                return
              }
              const icon = {
                url: place.icon,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(25, 25),
              }
              // Create a marker for each place.
              this.addMarker(
                new google.maps.Marker({
                  map: this.map,
                  icon,
                  title: place.name,
                  position: place.geometry.location,
                  draggable: this.draggableMarker,
                })
              )

              if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport)
              } else {
                bounds.extend(place.geometry.location)
              }
            })
            this.map.fitBounds(bounds)
          })
        }
      }
    },

    onDragMarker() {
      this.$emit('input', this.coords)
    },
    addMarker(googleMapMarker) {
      if (this.markersObj.length < this.limitPickMarker) {
        this.markersObj.push(googleMapMarker)
        // add event listener for drag to the new marker
        let lastMarkerIndex = this.markersObj.length - 1

        // drag event will automatically update the marker position in the markersObjArray
        this.markersObj[lastMarkerIndex].addListener('drag', this.onDragMarker)
        this.$emit('input', this.coords)
      }
    },

    // deleteMarker(lat, lng) {
    //   this.markersObj = this.markersObj.filter(m => m.position.lat() !== lat && m.position.lng() !== lng )
    //   this.coords = this.coords.filter(c => c.lat !== lat && c.lng !== lng)
    //
    //   this.$emit('input', this.coords)
    // },
    // Deletes all markers in the array by removing references to them.
    deleteMarkers() {
      this.hideMarkers()
      this.markersObj = []
      this.$emit('input', this.coords)
    },

    placeMarkerAndPanTo(latLng) {
      this.map.panTo(latLng)
    },

    // if marker clicked, this handler is triggered,set zoom to 13, and set center to the clicked marker
    markerClickHandler(marker) {
      this.map.setZoom(10)
      this.map.setCenter(marker.getPosition())
    },

    // Removes the markers from the map, but keeps them in the array.
    hideMarkers() {
      this.setMapOnAll(null)
    },

    showMarkers() {
      this.setMapOnAll(this.map)
    },
    setMapOnAll(map) {
      for (let i = 0; i < this.markersObj.length; i++) {
        this.markersObj[i].setMap(map)
      }
    },

    async getCurrentPosition() {
      try {
        let location = await getLocation()
        return {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        }
      } catch (err) {
        showToast(err.toString(), 'is-danger', 'is-top')
      }
    },
    async addRadius() {
      const radius = parseFloat(this.radius)
      if (radius > 0) {
        const google = await gmapsInit()

        if (this.drawCircle) {
          this.drawCircle.setRadius(radius)
          this.drawCircle.setCenter(this.value[0])
        } else {
          this.drawCircle = new google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            map: this.map,
            center: this.value[0],
            radius: radius,
          })
        }
        this.map.fitBounds(this.drawCircle.getBounds())
      }
      return
    },
  },
  async mounted() {
    try {
      // this.coords = this.value ?? []
      let current = await this.getCurrentPosition()

      await this.initMap(current)
    } catch (err) {
      showToast(err.toString(), 'is-danger', 'is-top')
    }
  },
  watch: {
    radius: {
      handler: 'addRadius',
      immediate: true,
      deep: true,
    },
    value: {
      handler: 'addRadius',
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style lang="scss">
.controls {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid transparent;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  height: 29px;
  margin-left: 17px;
  margin-top: 10px;
  outline: none;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

.controls:focus {
  border-color: #4d90fe;
}
</style>
