var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{class:'modal-office-type',attrs:{"title":_vm.isEditing ? 'Edit Company' : 'Create Company',"show":_vm.show,"isCancel":false,"isSubmit":false},on:{"hide":_vm.hide,"onSubmit":_vm.onSubmit},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14-px-normal form-timeoff-type",attrs:{"multipart":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"company name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Company Name","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Your Company Name"},model:{value:(_vm.formData.locationName),callback:function ($$v) {_vm.$set(_vm.formData, "locationName", $$v)},expression:"formData.locationName"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Address","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Your Company Address"},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Country","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Your Company Country"},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Province","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Province"},model:{value:(_vm.formData.province),callback:function ($$v) {_vm.$set(_vm.formData, "province", $$v)},expression:"formData.province"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"City","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"City"},model:{value:(_vm.formData.city),callback:function ($$v) {_vm.$set(_vm.formData, "city", $$v)},expression:"formData.city"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"postal code","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Post Code","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g 40226"},model:{value:(_vm.formData.postalCode),callback:function ($$v) {_vm.$set(_vm.formData, "postalCode", $$v)},expression:"formData.postalCode"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"lat_lng","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"Location Details"}},[_c('GoogleMap',{staticClass:"location-wrapper",attrs:{"enable-place-marker":"","limit-pick-marker":1,"marker-deletable":"","draggable-marker":"","radius":_vm.formData.wfoRadius},model:{value:(_vm.formData.markers),callback:function ($$v) {_vm.$set(_vm.formData, "markers", $$v)},expression:"formData.markers"}})],1)],1),_c('div',{staticClass:"column is-12"},[_c('p',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"Latitude"}},[_c('b-input',{attrs:{"disabled":"","placeholder":"Latitude"},model:{value:(_vm.lat),callback:function ($$v) {_vm.lat=$$v},expression:"lat"}})],1)],1),_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"Longitude"}},[_c('b-input',{attrs:{"disabled":"","placeholder":"Latitude"},model:{value:(_vm.lng),callback:function ($$v) {_vm.lng=$$v},expression:"lng"}})],1)],1),_c('div',{staticClass:"column is-8"},[_c('ValidationProvider',{attrs:{"name":"wfo radius","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Radius","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('div',{staticClass:"field has-addons"},[_c('div',{staticClass:"control"},[_c('b-input',{attrs:{"placeholder":"Radius"},model:{value:(_vm.formData.wfoRadius),callback:function ($$v) {_vm.$set(_vm.formData, "wfoRadius", $$v)},expression:"formData.wfoRadius"}})],1),_c('p',{staticClass:"control"},[_c('a',{staticClass:"button is-static is-large"},[_vm._v("Meter")])])])])]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"wfo required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Required","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-switch',{attrs:{"true-value":1,"false-value":0},model:{value:(_vm.formData.wfoRequired),callback:function ($$v) {_vm.$set(_vm.formData, "wfoRequired", $$v)},expression:"formData.wfoRequired"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"max capacity","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Max Capacity Office","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Max Capacity"},model:{value:(_vm.formData.maxCapacity),callback:function ($$v) {_vm.$set(_vm.formData, "maxCapacity", $$v)},expression:"formData.maxCapacity"}})],1)]}}],null,true)})],1),(_vm.formData.locationImage == null)?_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"Upload Office's Photo"}},[_c('b-upload',{attrs:{"accept":"image/*","drag-drop":"","expanded":""},on:{"input":_vm.onFileChange},model:{value:(_vm.formData.locationImage),callback:function ($$v) {_vm.$set(_vm.formData, "locationImage", $$v)},expression:"formData.locationImage"}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"image","size":"is-large"}})],1),_c('p',[_vm._v(" Drag image here or "),_c('span',{staticClass:"has-red-text"},[_vm._v("Browse")])])])])])],1)],1):_vm._e(),(_vm.formData.locationImage != null)?_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"File to Upload"}},[(_vm.formData.locationImage != null)?_c('div',{staticClass:"is-12 office-picture-container"},[(_vm.url)?_c('img',{attrs:{"src":_vm.url}}):_c('img',{attrs:{"src":_vm.getImageURL(_vm.formData)}}),_c('b-icon',{staticClass:"delete is-small office-button-container office-pointer-click",nativeOn:{"click":function($event){return _vm.deleteDropFile()}}})],1):_vm._e()])],1):_vm._e(),_c('b-button',{staticClass:"mb-2",attrs:{"expanded":"","native-type":"submit","type":"is-primary","loading":_vm.loadingSubmit}},[_vm._v(" Next ")])],1)])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }