<template>
  <Modal
    :title="isEditing ? 'Edit Company' : 'Create Company'"
    :show="show"
    :class="'modal-allowed-config'"
    submitLabel="Save Changes"
    :loadingSubmit="loadingSubmit"
    :isCancel="false"
    :isSubmit="true"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <ValidationObserver ref="observer2" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(submitAllowedCap)"
          class="is-label-14-px-normal office-text-bold-allowed-cap"
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <b-field>
                <b-switch
                  v-model="formData.isActive"
                  :left-label="true"
                  :value="true"
                  type="is-primary"
                >
                  Allowed Capacity
                </b-switch>
              </b-field>
            </div>
            <div class="column is-12">
              <b-field label="Allowed People">
                <b-input
                  v-model="formData.maxCapacity"
                  placeholder="e.g 20 people"
                  :disabled="!formData.isActive"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Start Date">
                <b-datepicker
                  :rules="formData.isActive ? 'required' : ''"
                  v-model="formData.startAt"
                  placeholder="Start Date"
                  :disabled="!formData.isActive"
                  trap-focus
                  editable
                ></b-datepicker>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="End Date">
                <b-datepicker
                  :rules="formData.isActive ? 'required' : ''"
                  v-model="formData.endAt"
                  placeholder="End Date"
                  :disabled="!formData.isActive"
                  trap-focus
                  editable
                ></b-datepicker>
              </b-field>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../../components/Modals/Modal.vue'

export default {
  components: { Modal },

  data() {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },
  },
}
</script>
