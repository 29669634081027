<template>
  <Modal
    :title="isEditing ? 'Edit Company' : 'Create Company'"
    :show="show"
    :class="'modal-office-type'"
    :isCancel="false"
    :isSubmit="false"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="is-label-14-px-normal form-timeoff-type"
          multipart
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider
                name="company name"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Company Name"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    v-model="formData.locationName"
                    placeholder="Your Company Name"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="address"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Address"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    v-model="formData.address"
                    placeholder="Your Company Address"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-12">
              <ValidationProvider
                name="country"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Country"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    v-model="formData.country"
                    placeholder="Your Company Country"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="province"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Province"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    v-model="formData.province"
                    placeholder="Province"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="city"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="City"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input v-model="formData.city" placeholder="City"></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="postal code"
                v-slot="{ errors }"
                rules="required|numeric"
              >
                <b-field
                  label="Post Code"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    v-model="formData.postalCode"
                    placeholder="e.g 40226"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-12">
              <ValidationProvider
                name="lat_lng"
                v-slot="{ errors }"
                rules="required"
              >
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <b-field label="Location Details">
                      <GoogleMap
                        class="location-wrapper"
                        enable-place-marker
                        :limit-pick-marker="1"
                        marker-deletable
                        v-model="formData.markers"
                        draggable-marker
                        :radius="formData.wfoRadius"
                      ></GoogleMap>
                    </b-field>
                  </div>
                  <div class="column is-12">
                    <p class="has-text-danger">{{ errors[0] }}</p>
                  </div>
                </div>
              </ValidationProvider>
            </div>

            <div class="column is-12">
              <b-field label="Latitude">
                <b-input
                  v-model="lat"
                  disabled
                  placeholder="Latitude"
                ></b-input>
              </b-field>
            </div>

            <div class="column is-12">
              <b-field label="Longitude">
                <b-input
                  v-model="lng"
                  disabled
                  placeholder="Latitude"
                ></b-input>
              </b-field>
            </div>

            <div class="column is-8">
              <ValidationProvider
                name="wfo radius"
                v-slot="{ errors }"
                rules="required|numeric"
              >
                <b-field
                  label="Radius"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <div class="field has-addons">
                    <div class="control">
                      <b-input
                        v-model="formData.wfoRadius"
                        placeholder="Radius"
                      />
                    </div>
                    <p class="control">
                      <a class="button is-static is-large">Meter</a>
                    </p>
                  </div>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-4">
              <ValidationProvider name="wfo required" v-slot="{ errors }">
                <b-field
                  label="Required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-switch
                    v-model="formData.wfoRequired"
                    :true-value="1"
                    :false-value="0"
                  />
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-12">
              <ValidationProvider
                name="max capacity"
                v-slot="{ errors }"
                rules="required|numeric"
              >
                <b-field
                  label="Max Capacity Office"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    v-model="formData.maxCapacity"
                    placeholder="Max Capacity"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12" v-if="formData.locationImage == null">
              <b-field label="Upload Office's Photo">
                <b-upload
                  v-model="formData.locationImage"
                  accept="image/*"
                  drag-drop
                  expanded
                  @input="onFileChange"
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="image" size="is-large"></b-icon>
                      </p>
                      <p>
                        Drag image here or
                        <span class="has-red-text">Browse</span>
                      </p>
                    </div>
                  </section>
                </b-upload>
              </b-field>
            </div>

            <div class="column is-12" v-if="formData.locationImage != null">
              <b-field label="File to Upload">
                <div
                  class="is-12 office-picture-container"
                  v-if="formData.locationImage != null"
                >
                  <img v-if="url" :src="url" />
                  <img :src="getImageURL(formData)" v-else />
                  <b-icon
                    class="delete is-small office-button-container office-pointer-click"
                    @click.native="deleteDropFile()"
                  ></b-icon>
                </div>
              </b-field>
            </div>
            <b-button
              expanded
              native-type="submit"
              type="is-primary"
              :loading="loadingSubmit"
              class="mb-2"
            >
              Next
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../../components/Modals/Modal.vue'
import GoogleMap from '../../../components/GoogleMap'

export default {
  components: { Modal, GoogleMap },

  data() {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    lat: {
      type: Number,
      default: null,
    },
    lng: {
      type: Number,
      default: null,
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },
    /**
     * Emit Submit modal.
     */
    getImageURL(item) {
      return item.locationImage
        ? process.env.VUE_APP_API_URL + item.locationImage
        : '/images/default-profile-picture-male.png'
    },
    /**
     * Emit Submit modal.
     */
    deleteDropFile(event) {
      this.$emit('deleteDropFile', event)
    },
    /**
     * Emit Submit modal.
     */
    onFileChange(event) {
      this.$emit('onFileChange', event)
    },
  },
}
</script>
